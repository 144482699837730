import { Box, Button } from "@material-ui/core";
import {
    Page,
    Text,
    View,
    Document,
    Image,
    StyleSheet,
    Font,
    pdf,
    Canvas,
} from "@react-pdf/renderer";
import { useFormikContext } from "formik";
import React, { ReactElement, useState, CSSProperties, useEffect } from "react";
import FormattedText from "../../../../../../Components/FormattedText";
import Dialog from "./Components/Dialog";
import logo from "../../../ImageAgreement/Assets/logo.png";
import Cookies from "js-cookie"
import { useTranslation } from "react-i18next";
import { sendQuestionnaire } from "../../../../../../Providers/backend";
import { PageTitle, Question } from "../../../../../../Types";

const SubmitDialog = (
    props: {
        canvas: any;
        pageTitles: PageTitle[],
        questions: Question[];
        disabled?: boolean;
        text?: string;
        btnStyle?: CSSProperties;
        btnTextStyle?: CSSProperties;
    }): ReactElement => {
    Font.register({
        family: "MontserratRegular",
        src: "https://cdn.jsdelivr.net/npm/@expo-google-fonts/montserrat@0.2.3/Montserrat_500Medium.ttf",
    });
    Font.register({
        family: "Maven",
        src: 'https://cdn.jsdelivr.net/npm/myriad-font@0.0.1/fonts/myriad/myriad-set-pro_medium.woff',
    });
    const { t } = useTranslation();
    const styles = StyleSheet.create({
        page: {
            fontFamily: "Maven",
            margin: 5,
            fontSize: 24,
            color: "white",
            backgroundColor: "black",
            textAlign: "center"
        },
        radioButton: {
            position: 'relative',
            width: 10,
            height: 10,
            borderRadius: 10,
            borderWidth: 2,
            borderColor: '#818589',
            marginRight: 5,
            justifyContent: 'center',
            alignItems: 'center',
        },
        checkedRadio: {
            backgroundColor: '#8d2d42', // Change color to represent the checked state
            padding: 1
        },
        inline: {
            margin: 2,
            fontFamily: "MontserratRegular",
            fontSize: 10
        },
        text: {
            fontFamily: "MontserratRegular",
            fontSize: 10,
            marginBottom: 3,
            flexWrap: 'wrap',
            width: "75%"

        },
        text2: {
            fontFamily: "MontserratRegular",
            fontSize: 12,
            color: "#8d2d42",

        },
        text3: {
            fontFamily: "MontserratRegular",
            fontSize: 9,
            color: "#8d2d42",

        },
        answerLeft: {
            fontFamily: "MontserratRegular",
            fontSize: 9,
            color: "#8d2d46",
            textAlign: "right",
        },
        answerRight: {
            fontFamily: "MontserratRegular",
            fontSize: 9,
            color: "#8d2d46",
            textAlign: "right",
        },
        titles: {
            margin: 3,
            textDecoration: "underline", textDecorationColor: "#8d2d46",
            fontFamily: "MontserratRegular",
            fontSize: 14,
            textAlign: "left",
        },
        confirms: {
            fontFamily: "MontserratRegular",
            fontSize: 11,
            textAlign: "justify",
            margin: 10,

        },
        radioButtonGroupLeft: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        radioButtonGroupRight: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        justifyContentText: {
            justifyContent: 'space-between',
        },
    })

    const { submitForm, getFieldProps } = useFormikContext();
    const patientID = Cookies.get("patientID") + ""
    const facility = Cookies.get("facilityName") + ""

    const renderQuestionsRight = (questions: any, page: number) => {
        return questions.map((item: any, index: any) => (
            <View style={{ flexDirection: "column" }} key={index}>
                {((item.page === page) && item.answer.answerType === "yes_no") ?
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.text}>{item.questionText} </Text>
                        <View style={styles.radioButtonGroupRight}>
                            <View style={getFieldProps(item.questionID)
                                .value
                                .answerValue === "Yes" || getFieldProps(item.questionID)
                                    .value
                                    .answerValue === "Ja" ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                            <Text style={styles.text2}>Yes</Text><Text>{"  "}</Text>
                            <View style={getFieldProps(item.questionID)
                                .value
                                .answerValue === "No" || getFieldProps(item.questionID)
                                    .value
                                    .answerValue === "Nein" ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                            <Text style={styles.text2}>No</Text>
                        </View>
                    </View>
                    :
                    (((item.page === page) && item.answer.answerType !== "yes_no")) &&
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Text style={styles.text}>{item.questionText} </Text>
                        <View style={[styles.radioButtonGroupRight, { backgroundColor: "white", width: "65%" }]}>
                            <Text>{" "}</Text>
                            <Text style={styles.answerRight}>{getFieldProps(item.questionID)
                                .value
                                .answerValue}</Text>
                        </View>
                    </View>
                }
            </View>
        ))
    }
    const renderQuestionsLeft = (questions: any, page: number) => {

        // Helper functions to check if answer is 'Yes' or 'No'
        const isYesAnswer = (answerValue: string) => ["Yes", "Ja", "да", "Да", "oui", "Sí", "Sim", "Da", "si", "Kyllä"].includes(answerValue);
        const isNoAnswer = (answerValue: string) => ["No", "Nein", "не", "Нет", "Non", "Não", "Nu", "no", "Nej", "Nee", "Nei", "Ei"].includes(answerValue);

        return questions.map((item: any, index: any) => {
            const answerValue = getFieldProps(item.questionID).value.answerValue;

            // Check if the current question should be rendered for the given page
            if (item.page !== page) return null;

            return (
                <View style={{ flexDirection: "column" }} key={index}>
                    {item.answer.answerType === "yes_no" ? (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={styles.text}>{item.questionText} </Text>
                            <View style={styles.radioButtonGroupLeft}>
                                <View style={isYesAnswer(answerValue) ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                                <Text style={styles.text3}>Yes</Text>
                                <Text>{"  "}</Text>
                                <View style={isNoAnswer(answerValue) ? [styles.radioButton, styles.checkedRadio] : styles.radioButton} />
                                <Text style={styles.text3}>No</Text>
                            </View>
                        </View>
                    ) : (
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={styles.text}>{item.questionText} </Text>
                            <View style={[styles.radioButtonGroupLeft, { backgroundColor: "#F1F2F2", width: "65%" }]}>
                                <Text style={styles.answerLeft}>{answerValue}</Text>
                            </View>
                        </View>
                    )}
                </View>
            );
        });
    };
    const QuestionnaireF3T = () => (
        <Document>
            <Page size="A4">
                <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                    <View style={{ padding: 2, width: "40%" }}>
                        <Image src={logo} style={{ width: "90%", alignSelf: "center" }}></Image>
                        <Text style={styles.page}>Medical History Form</Text>
                        <Text style={styles.inline}>It is very important for our Clinic and
                            professional to understand your medical
                            needs before any examination or treatment is
                            carried out. Any information given to us is
                            strictly confidential.</Text>
                        <View style={{ height: "15px" }}></View>
                        <Text style={{ fontFamily: "MontserratRegular", fontSize: 14 }}>Patient ID: <Text style={{ backgroundColor: "#f1f2f2" }}>{patientID}</Text></Text>
                        <View style={{ height: "15px" }}></View>
                        {renderQuestionsLeft(props.questions, 0)}
                        <Text style={styles.titles}>{props.pageTitles[1].title}</Text>
                        {renderQuestionsLeft(props.questions, 1)}
                        <Text style={styles.titles}>{props.pageTitles[2].title}</Text>
                        {renderQuestionsRight(props.questions, 2)}
                    </View>
                    <View style={{ padding: 2, width: "50%", backgroundColor: "#F1F2F2", borderRadius: 10, marginTop: 40 }}>
                        <Text style={styles.titles}>{props.pageTitles[3].title}</Text>
                        {renderQuestionsRight(props.questions, 3)}
                        <Text style={styles.titles}>{props.pageTitles[4].title}</Text>
                        {renderQuestionsRight(props.questions, 4)}
                    </View>
                </View>
            </Page >
            <Page>
                <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                    <View style={{ padding: 2, width: "40%" }}>
                        <Image src={logo} style={{ width: "90%", alignSelf: "center" }}></Image>
                        <Text style={styles.titles}>{props.pageTitles[5].title}</Text>
                        {renderQuestionsLeft(props.questions, 5)}
                        <Text style={styles.titles}>{props.pageTitles[6].title}</Text>
                        {renderQuestionsLeft(props.questions, 6)}


                    </View>
                    <View style={{ padding: 2, width: "50%", backgroundColor: "#F1F2F2", borderRadius: 10, marginTop: 60 }}>
                        <Text style={styles.titles}>{props.pageTitles[7].title}</Text>
                        {renderQuestionsRight(props.questions, 7)}
                        <Text style={styles.titles}>{props.pageTitles[8].title}</Text>
                        {renderQuestionsRight(props.questions, 8)}

                    </View>
                </View>
            </Page>
            <Page>
                <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                    <View style={{ padding: 2, width: "40%", borderRadius: 10, marginBottom: 20 }}>
                        <Image src={logo} style={{ width: "90%", alignSelf: "center" }}></Image>
                        <Text style={styles.titles}>{props.pageTitles[9].title}</Text>
                        {renderQuestionsLeft(props.questions, 9)}
                    </View>
                    <View style={{ padding: 2, width: "50%", borderRadius: 10, marginBottom: 20, marginTop: 60, backgroundColor: "#F1F2F2", }}>
                        <Text style={styles.titles}>{props.pageTitles[10].title}</Text>
                        {renderQuestionsRight(props.questions, 10)}
                        <Text style={styles.titles}>{props.pageTitles[11].title}</Text>
                        {renderQuestionsRight(props.questions, 11)}
                        <Text style={styles.titles}>{props.pageTitles[12].title}</Text>
                        {renderQuestionsRight(props.questions, 12)}
                    </View>
                </View>
                <View>
                    <Text style={styles.titles}>
                        Please confirm
                    </Text>
                    <View style={{ flexDirection: 'row', alignItems: 'center', margin: 10 }}>
                        <View style={[styles.radioButton, styles.checkedRadio]} />
                        <Text style={styles.confirms}>
                            I give my permission to have my data processed and stored electronically as well as photos to be taken for
                            clinical purposes
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row', alignItems: 'center', margin: 10 }}>
                        <View style={[styles.radioButton, styles.checkedRadio]} />
                        <Text style={styles.confirms}>
                            I, the undersigned, to the best of my knowledge, have provided accurate information relating to my health,
                            and if any changes are required, I will notify the Dental Clinic as soon as is practicable. I hereby declare that
                            the information above is true, complete and correct. In case any of the above information is found to be
                            false or untrue or misleading, I am aware that I may be held liable for it.
                        </Text>
                    </View>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-around", margin: 5 }}>
                    <View style={{ flexDirection: "column" }}><Text>Location</Text><Text style={[styles.text2, { backgroundColor: "#F1F2F2" }]}>London</Text></View>
                    <View style={{ flexDirection: "column" }}><Text>Date</Text><Text style={[styles.text2, { backgroundColor: "#F1F2F2" }]}>{String(new Date().getDate()).padStart(2, '0') + "." + String(new Date().getMonth() + 1).padStart(2, '0') + "." + new Date().getFullYear()}</Text></View>
                    {props.canvas && <View style={{ flexDirection: "column" }}><Text>Your Signature</Text><Image src={props.canvas} style={{ width: "100px", height: "100px", alignSelf: "center" }}></Image></View>}
                </View>
            </Page>
        </Document >
    );
    const QuestionnaireDACH = () => (
        <Document>
            <Page size="A4">
                <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                    <View style={{ padding: 2, width: "40%" }}>
                        <Image src={logo} style={{ width: "90%", alignSelf: "center" }}></Image>
                        <Text style={styles.page}>Medical History Form</Text>
                        <Text style={styles.inline}>It is very important for our Clinic and
                            professional to understand your medical
                            needs before any examination or treatment is
                            carried out. Any information given to us is
                            strictly confidential.</Text>
                        <View style={{ height: "15px" }}></View>
                        <Text style={{ fontFamily: "MontserratRegular", fontSize: 14 }}>Patient ID: <Text style={{ backgroundColor: "#f1f2f2" }}>{patientID}</Text></Text>
                        <View style={{ height: "15px" }}></View>
                        {renderQuestionsLeft(props.questions, 0)}
                        <Text style={styles.titles}>{props.pageTitles[1].title}</Text>
                        {renderQuestionsLeft(props.questions, 1)}
                    </View>
                    <View style={{ padding: 2, width: "50%", backgroundColor: "#F1F2F2", borderRadius: 10, marginTop: 40 }}>
                        <Text style={styles.titles}>{props.pageTitles[2].title}</Text>
                        {renderQuestionsRight(props.questions, 2)}
                        <Text style={styles.titles}>{props.pageTitles[3].title}</Text>
                        {renderQuestionsRight(props.questions, 3)}
                        <Text style={styles.titles}>{props.pageTitles[4].title}</Text>
                        {renderQuestionsRight(props.questions, 4)}
                    </View>
                </View>
            </Page >
            <Page>
                <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                    <View style={{ padding: 2, width: "40%" }}>
                        <Image src={logo} style={{ width: "90%", alignSelf: "center" }}></Image>
                        <Text style={styles.titles}>{props.pageTitles[5].title}</Text>
                        {renderQuestionsLeft(props.questions, 5)}
                        <Text style={styles.titles}>{props.pageTitles[6].title}</Text>
                        {renderQuestionsLeft(props.questions, 6)}
                    </View>
                    <View style={{ padding: 2, width: "50%", backgroundColor: "#F1F2F2", borderRadius: 10, marginTop: 50 }}>
                        <Text style={styles.titles}>{props.pageTitles[7].title}</Text>
                        {renderQuestionsRight(props.questions, 7)}
                        <Text style={styles.titles}>{props.pageTitles[8].title}</Text>
                        {renderQuestionsRight(props.questions, 8)}
                        <Text style={styles.titles}>{props.pageTitles[9].title}</Text>
                        {renderQuestionsRight(props.questions, 9)}
                    </View>
                </View>
            </Page>
            <Page>
                <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
                    <View style={{ padding: 2, width: "40%", borderRadius: 10, marginBottom: 20 }}>
                        <Image src={logo} style={{ width: "90%", alignSelf: "center" }}></Image>
                        <Text style={styles.titles}>{props.pageTitles[10].title}</Text>
                        {renderQuestionsLeft(props.questions, 10)}
                        <Text style={styles.titles}>{props.pageTitles[11].title}</Text>
                        {renderQuestionsLeft(props.questions, 11)}
                        <Text style={styles.titles}>{props.pageTitles[12].title}</Text>
                        {renderQuestionsLeft(props.questions, 12)}
                    </View>
                    <View style={{ padding: 2, width: "50%", borderRadius: 10, marginBottom: 20, marginTop: 30, backgroundColor: "#F1F2F2", }}>
                        <Text style={styles.titles}>{props.pageTitles[13].title}</Text>
                        {renderQuestionsRight(props.questions, 13)}
                        <Text style={styles.titles}>{props.pageTitles[14].title}</Text>
                        {renderQuestionsRight(props.questions, 14)}
                        <Text style={styles.titles}>{props.pageTitles[15].title}</Text>
                        {renderQuestionsRight(props.questions, 15)}
                        <Text style={styles.titles}>{props.pageTitles[16].title}</Text>
                        {renderQuestionsRight(props.questions, 16)}
                    </View>
                </View>
                <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 5 }}>
                    <Text style={[styles.text, { textAlign: 'center' }]}>
                        {t("healthSign")}
                    </Text>
                </View>
                <View style={{ flexDirection: "row", justifyContent: "space-around", margin: 5 }}>
                    <View style={{ flexDirection: "column" }}><Text>Location</Text><Text style={[styles.text2, { backgroundColor: "#F1F2F2" }]}>Varna</Text></View>
                    <View style={{ flexDirection: "column" }}><Text>Date</Text><Text style={[styles.text2, { backgroundColor: "#F1F2F2" }]}>{String(new Date().getDate()).padStart(2, '0') + "." + String(new Date().getMonth() + 1).padStart(2, '0') + "." + new Date().getFullYear()}</Text></View>
                    {props.canvas && <View style={{ flexDirection: "column" }}><Text>Your Signature</Text><Image src={props.canvas} style={{ width: "100px", height: "100px", alignSelf: "center" }}></Image></View>}
                </View>
            </Page>
        </Document >
    );

    const reader = new FileReader();
    const uploadFile = async () => {
        //generate blob from pdf
        setTimeout(async () => {
            if (props.questions.length > 0) {
                const pdfBlob = await pdf(facility === "Dentaprime London" ? <QuestionnaireF3T /> : <QuestionnaireDACH />).toBlob();
                reader.readAsDataURL(pdfBlob);
                reader.onloadend = async function () {
                    const cutFileString = String(reader.result);
                    const emailAndBirthdateQuestions: any = props.questions.filter((o: any) => o.questionPatientField === 'email' || o.questionPatientField === 'birthdate');
                    //28 symbols string for base64 pdf string to remove unnecessary part of string
                    sendQuestionnaire(cutFileString, patientID, Cookies.get("facilityName") + "", getFieldProps(emailAndBirthdateQuestions.filter((o: any) => o.questionPatientField === "birthdate")[0].questionID)
                        .value
                        .answerValue, getFieldProps(emailAndBirthdateQuestions.filter((o: any) => o.questionPatientField === "email")[0].questionID)
                            .value
                        .answerValue, Cookies.get("Language") + "".toLowerCase(), Cookies.get("sid") + "")
                };
            }
        }, 200)
    };
    const [open, setOpen] = useState(false);

    return <Box>
        <Button
            style={props.btnStyle}
            variant='contained'
            disabled={props.disabled}
            onClick={() => { setOpen(true); uploadFile() }}
        >
            <FormattedText style={props.btnTextStyle} text='Submit' />
        </Button>
        <Dialog
            title='Submit'
            open={open}
            handleClose={() => setOpen(false)}
            handleLeftButtonClick={() => setOpen(false)}
            handleRightButtonClick={() => {
                uploadFile();
                submitForm();
            }}
        >
            <FormattedText text={props.text || "Are you sure you want to submit?"} />
        </Dialog>
    </Box>;
};

export default SubmitDialog;